import styled from "styled-components";
import { Input, Div, Text } from "../../StyledComponents";

export const InputInlineText = styled.p`
  color: #363636;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  position: absolute;
  font-weight: 400;
  top: 58%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0px);
  cursor: pointer;

  @media (min-width: 2000px) {
    font-size: 16px;
  }
`;

export const ReasonInput = styled.textarea`
  width: 55%;
  height: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  padding: 8px;
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const AddApptFooter = styled(Div)``;

export const AppointmentInput = styled(Input)`
  height: 100%;
  transition: height 0.3s ease-in;
  border-radius: 6px;
  width: 100%;
  border: ${({ errorBorder }) =>
    errorBorder ? "1px dashed #F65160" : "1px dashed #E5E5E5"};

  background-image: url(${(p) => p.backgroundImage});
  background-repeat: no-repeat;
  background-position: center 27%;
  cursor: pointer;

  &::placeholder {
    font-family: "Inter";
    font-weight: 400;
    font-size: 13px;
    color: transparent;
  }

  & ~ p {
    display: block;
  }
  & + svg#inFocus1 {
    display: none;
  }
  & + svg#inFocus1 {
    margin-right: 12px;
    position: absolute;
    top: 10px;
    left: 16px;
  }

  ${(p) =>
    p.dropOpen &&
    `
  
    background-image: none;
    height: 34px;
    border: 1px solid #407bff !important;
    box-shadow: 0px 2px 0px rgba(64, 123, 255, 0.08),
      0px 2px 4px rgba(64, 123, 255, 0.15);
    border-radius: 6px 6px 0px 0px;
    outline: none;
    padding-left: 44px;  
    
    &::placeholder {
      color: #7e7c7c;
    }
    
    & + svg#inFocus1 {
      display: block;
    }

    & ~ p{
      display: none;
    }
  `}
`;

export const AppointmentInput2 = styled(AppointmentInput)``;
export const AppointmentInput3 = styled(AppointmentInput)``;

export const DropdownDiv = styled(Div)`
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 1001;
  background: #fff;
  max-height: 185px;
  overflow-x: ${(p) => (p.overflowX ? p.overflowX : "auto")};
  position: relative;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 6px;
  }
`;

export const DropdownText = styled(Div)`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #414141;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding: ${(p) => p.padding && p.padding};
  cursor: pointer;
  &:hover {
    background: #ececec;
  }
`;

export const InlinePlaceholder = styled.div`
  & svg {
    margin-right: 12px;
  }
  position: absolute;
  top: 10px;
  left: 16px;
`;

export const ChangeText = styled.button`
  font-weight: 400;
  border: none;
  font-size: 12px;
  color: #407bff;
  width: 100%;
  text-align: right;
  padding: 16px 16px 0px 0px;

  cursor: pointer;
  background: none;

  @media (max-width: ${({ mediaWidth }) => mediaWidth && mediaWidth}) {
    display: none;
  }

  ${(p) =>
    p.absolute &&
    `
 position : absolute;
 margin : 0;
 width : auto;
 padding : 0px !important;
 top : ${p.top ? p.top : "16%"};
 right : 16px;
  `}
`;

export const TimeDateDiv = styled(Div)`
  width: 100%;
  height: 270%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 16px 25px;
  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 270%;
    }
  }

  @media (width: 1440px) {
    padding: 16px 25px 12px;
    height: 265%;
  }
`;

export const DateInput = styled.button`
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232934;
  width: 100%;
  background: white;
  border: none;
  cursor: pointer;
`;

export const DateChangeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 20%;
  background: none;

  ${(p) =>
    p.leftArrow &&
    `
  border-bottom-left-radius : 4px;
  border-top-left-radius : 4px;
  border-width: 0 1px 0 0;
  border-color: #d8e0f0; 
  border-style: solid;
  `}
  ${(p) =>
    p.rightArrow &&
    `
  border-bottom-right-radius : 4px;
  border-top-right-radius : 4px;
  border-width: 0 0 0 1px;
  border-color: #d8e0f0;
  border-style: solid;
  `}
`;

export const SlideContainer = styled.div`
  height: 93%;
`;
export const Sliceder = styled.div`
  width: 100%;
  height: 27%;
  position: relative;
  padding: 8px 0px;
`;
export const Slicedes = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 34px);
  height: 100%;
  position: relative;
`;

export const Slide = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: left 0.5s ease-in;
  ${(p) => p.width && `width: ${p.width};`}
  height : 100%;

  ${(p) =>
    p.slideLeft
      ? `  
      left: ${p.absoluteLeft};    
  `
      : `left : 0px;`}
`;

export const NextButton = styled.button`
  border: none;
  background: white;
  color: #407bff;
  height: 36px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 7px;
  & svg {
    color: #407bff;
    margin-right: 4px;
    margin-top: 1px;
  }
`;

export const TimePills = styled.button`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8e0f0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 4px;
  width: ${({ width }) => width && width};
  cursor: pointer;
  background: white;
  height: 100%;

  @media (max-width: 1440px) {
    font-size: 12px;
    line-height: 13px;
    padding: 6px;
  }

  @media (max-width: 1350px) {
    font-size: 11px;
    line-height: 12px;
    padding: 4px;
  }

  @media (max-width: 1200px) {
    font-size: 9px;
    line-height: 11px;
    padding: 4px;
  }

  @media (max-width: 1024px) {
    font-size: 8px;
    line-height: 9px;
    padding: 2px;
  }

  ${(p) =>
    p.Selected
      ? `
  background-color : #407BFF;
  color : white;
  `
      : `color: #b7b7b7; `}
  ${(p) =>
    p.disabled &&
    `
      color : white;
      background-color : #B7B7B7;
      cursor: not-allowed;
      `}
`;

export const SlidesHolder = styled.div`
  width: 100%;
  height: calc(100% - 19px);
  display: flex;
  align-items: center;
  margin-top: 8px;

  @media (width: 1440) {
    margin-top: 6px;
    height: calc(100% - 20px);
  }
`;

export const TimeSelectorDiv = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  width: 45%;
  border: 1px solid #d8e0f0;
  border-radius: 4px;
  height: 15%;
`;

export const DivVariant2 = styled(Div)`
  width: 69.73%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.06), 0px 16px 16px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 60px;
  height: max-content;
  margin-top: 40px;

  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: 1200px) {
    width: 75%;
  }
`;

export const HiddenButton = styled.button`
  color: #407bff;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 20px;
  border: none;
  background: white;
  display: none;
  cursor: ${({ cursor }) => cursor && cursor};
  @media (max-width: ${({ mediaWidth }) => mediaWidth && mediaWidth}) {
    display: block;
  }
`;

export const InitialsText = styled.div`
  height: ${({ height }) => height || "28px"};
  width: ${({ width }) => width || "28px"};
  border-radius: 50%;
  background: #a8aebe;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin || "6px 10px 6px 16px"};
  padding: ${({ padding }) => padding && padding};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "12px")};
  font-family: "Work Sans", sans-serif;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
`;
export const Text1 = styled(Text)`
  &.createfont {
    font-size: 22px;
    @media (max-width: 980px) {
      font-size: 21px;
    }
  }
  &.availabilityerr {
    font-size: 12px;
  }
  &.availabilityerr1 {
    font-size: 12px;
    @media (max-width: 1180px) {
      font-size: 10px;
    }
    @media (max-width: 1010px) {
      font-size: 9px;
    }
    @media (max-width: 980px) {
      font-size: 8px;
    }
  }
`;
