import React from "react";
import { Link } from "react-router-dom";

import TopNav from "../TopNav";
import { Div, PageSubTitle } from "../StyledComponents";
import { MenuHead } from "./styles";
import { HiOutlineChevronRight } from "react-icons/hi";

const SettingsNew = () => {
  return (
    <>
      <TopNav title="Settings" />
      <Div padding="20px 40px 40px 40px">
        <PageSubTitle fontSize="18px">Account</PageSubTitle>

        <Link to="/settings/changepassword">
          <Div
            border="1px solid rgba(46, 46, 46, 0.10)"
            marginTop="8px"
            borderRadius="4px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px 20px"
          >
            <MenuHead>Change Password</MenuHead>
            <Div cursor="pointer" flexCenterVertical>
              <HiOutlineChevronRight />
            </Div>
          </Div>
        </Link>
        <PageSubTitle fontSize="18px" marginTop="24px">
          About
        </PageSubTitle>
        <Div
          border="1px solid rgba(46, 46, 46, 0.10)"
          marginTop="8px"
          borderRadius="4px"
          marginBottom="16px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="16px 20px"
        >
          <MenuHead>Privacy Policy</MenuHead>
          <Div cursor="pointer" flexCenterVertical>
            <HiOutlineChevronRight />
          </Div>
        </Div>
        <Div
          border="1px solid rgba(46, 46, 46, 0.10)"
          marginTop="8px"
          borderRadius="4px"
          marginBottom="16px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="16px 20px"
        >
          <MenuHead>Terms & Condition</MenuHead>
          <Div cursor="pointer" flexCenterVertical>
            <HiOutlineChevronRight />
          </Div>
        </Div>
        <Div
          border="1px solid rgba(46, 46, 46, 0.10)"
          marginTop="8px"
          borderRadius="4px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="16px 20px"
        >
          <MenuHead>Copyright</MenuHead>
          <Div cursor="pointer" flexCenterVertical>
            <HiOutlineChevronRight />
          </Div>
        </Div>
        <PageSubTitle fontSize="18px" marginTop="24px">
          General
        </PageSubTitle>
        <Div
          border="1px solid rgba(46, 46, 46, 0.10)"
          marginTop="8px"
          borderRadius="4px"
          marginBottom="16px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="16px 20px"
        >
          <MenuHead>Invite Others</MenuHead>
          <Div cursor="pointer" flexCenterVertical>
            <HiOutlineChevronRight />
          </Div>
        </Div>
        <Link to="/settings/feedback">
          <Div
            border="1px solid rgba(46, 46, 46, 0.10)"
            marginTop="8px"
            borderRadius="4px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px 20px"
          >
            <MenuHead>Feedback</MenuHead>
            <Div cursor="pointer" flexCenterVertical>
              <HiOutlineChevronRight />
            </Div>
          </Div>
        </Link>
      </Div>
    </>
  );
};

export default SettingsNew;
